<template>
  <div class="flex flex-row justify-center">
    <div class="max-w-lg flex-wrap md:flex-no-wrap flex w-full mx-4 flex-col md:flex-row items-center bg-white shadow rounded z-40">
      <div class="flex flex-col md:h-full justify-center w-full md:w-1/2 text-center tracking-wide bg-grey rounded-t md:rounded-l p-8">
        <h1 class="text-28 text-grey-dark pb-2">
          Special offer
        </h1>
        <p class="text-grey-dark font-hairline pb-px">
          Join today & get
        </p>
        <p class="text-green text-40 py-2">
          50% off
        </p>
        <p class="text-green uppercase font-bold pt-px">
          <span class="text-grey-dark font-thin">Off 12 & 24 month internet plans</span>
          <span class="text-grey-dark text-xs font-thin">offer ends 31st December 2020<br>min cost $479.40, excludes modem</span>
        </p>
      </div>
      <div class="w-full md:w-1/2 text-center tracking-wide rounded-b md:rounded-r p-8">
        <p class="text-green text-28 pb-2">
          Don't Miss Out!
        </p>
        <div class="w-full pt-8 md:pt-0 uppercase text-center">
          <p class="flex items-center justify-center uppercase font-bold text-grey-dark mb-0 pb-0 leading-none pt-2 text-base">
            <tick-alt class="text-green h-8 -ml-8" />Free Activation
          </p>
          <p class="flex items-center justify-center uppercase font-bold text-grey-dark pt-0 -mt-2 leading-none text-base">
            <tick-alt class="text-green h-8 -ml-8" />Unlimited Data&nbsp;
          </p>
          <button
            class="text-grey-dark text-xs font-semibold tracking-wide uppercase rounded-full cursor-pointer mt-4 py-4 px-10 border-2 border-green"
            @click="focusAddressSearch()"
          >
            <span class="flex flex-row items-center justify-center">
              <span>Check Your Address Now</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TickAlt from '~/components/Icons/TickAlt.vue'

export default {
  components: {
    TickAlt
  },
  data() {
    return {
      siteId: process.env.siteId,
      brand: process.env.brand
    }
  },
  methods: {
    focusAddressSearch() {
      document.getElementById('address-search').focus()
    }
  }
}
</script>

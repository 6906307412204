
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Brand } from '~/lib/common/brand'
import { Faq } from '~/lib/faq/FaqProviderInterface'

@Component
export default class FaqList extends Vue {
  @Prop({ default: 'GOT QUESTIONS?'})
  title: string

  @Prop({ default: 'We know in the world of Internet and tech, things can get a little confusing. So we’ve put together some of our frequently asked questions to help you get up to speed.'})
  subtitle: string

  @Prop()
  faqs: Faq[]

  activeQuestion: number | null = null

  changeActiveQuestion(id: number) {
    this.activeQuestion = id
  }

  activeQuestionHeader(id: number) {
    const brand = (process.env.brand as any).code as Brand
    const borderColor = brand === 'FUZ' || brand === 'HIS' ? 'border-uniti-gold' : 'border-primary'
    return this.activeQuestion === id || id === 0
      ? ` border-b-4 ${borderColor}`
      : ' border-b-2 border-grey'
  }
}
